import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {ProxyComponent} from './components/proxy/proxy.component';
import {BrokerContractComponent} from './components/broker-contract/broker-contract.component';
import {BrokerContractItemComponent} from './components/broker-contract-item/broker-contract-item.component';
import {DocumentGeneratorService} from './services/document-generator.service';
import {GeoLocationService} from './services/geolocation.service';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';

import {HttpClientModule} from '@angular/common/http';
import {AppInitService, AppInitServiceFactory} from './services/app-init.service';
import {DownloadButtonComponent} from './components/download-button/download-button.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AngularCommonsModule, LogoutService, PersonsModule, TauresMenueModule} from '@taures/angular-commons';
import {OauthLogoutService} from './services/oauth-logout.service';
import {OAuthModule} from 'angular-oauth2-oidc';
import {MaterialModule} from './material.module';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {MapGeocoder} from "@angular/google-maps";

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const APP_DATE_FORMAT = {
  parse: {
    dateInput: 'L',
    monthInput: 'MMMM',
    timeInput: 'LT',
    datetimeInput: 'L LT'
  },
  display: {
    dateInput: 'L',
    monthInput: 'MMMM',
    datetimeInput: 'L LT',
    timeInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    popupHeaderDateLabel: 'ddd, DD MMM'
  },
};

@NgModule({
  declarations: [
    AppComponent,
    ProxyComponent,
    BrokerContractComponent,
    BrokerContractItemComponent,
    DownloadButtonComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    OAuthModule.forRoot({resourceServer: {sendAccessToken: true}}),
    AngularCommonsModule,
    PersonsModule,
    TauresMenueModule,
  ],
  providers: [
    DocumentGeneratorService,
    GeoLocationService,
    MapGeocoder,
    AppInitService,
    {provide: LogoutService, useClass: OauthLogoutService},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMAT},
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitServiceFactory,
      multi: true,
      deps: [AppInitService]
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {disableClose: true, hasBackdrop: true}
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMAT},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
