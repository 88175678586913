export const environment = {
  production: true,
  oauth: {
    issuer: 'ISSUER_URL',
    clientId: 'CLIENT_ID',
    scope: 'openid email',
    responseType: 'code',
    disablePKCE: true // this is not recommended, but otherwise, the keycloak.js adapter would not work
  },
  googleMapsAPIKey: 'AIzaSyD0ew9LFuWfVCGosKDT0XQIe1twS9B38uM'
};
