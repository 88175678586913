import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

import {ProxyContractData} from '../../services/document-generator.service';
import {GeoLocationService} from '../../services/geolocation.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-proxy',
  templateUrl: './proxy.component.html',
  styleUrls: ['./proxy.component.css']
})
export class ProxyComponent implements OnInit, OnDestroy {
  data: ProxyContractData = {} as any;
  private dataSubscription: Subscription;
  private locationSubscription: Subscription;

  constructor(private route: ActivatedRoute,
              private geoLocationService: GeoLocationService,
              private titleService: Title,
              private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.dataSubscription = this.route.data.subscribe(({data}) => {
        this.titleService.setTitle(`Vollmacht - ${data.firstName}, ${data.lastName} - TauRes`);
        this.data = data;
      }
    );
    this.locationSubscription = this.geoLocationService.getLocality().subscribe((locality) => {
      this.data.signaturePlace = locality;
      this.data.signatureDate = new Date();
      this.changeDetector.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this.locationSubscription.unsubscribe();
  }
}
