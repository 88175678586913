import {Component, EventEmitter, Input, Output} from '@angular/core';


import {BrokerContractItem} from "../../services/document-generator.service";

@Component({
  selector: 'app-broker-contract-item',
  templateUrl: './broker-contract-item.component.html',
  styleUrls: ['./broker-contract-item.component.css']
})
export class BrokerContractItemComponent {
  @Input() item: BrokerContractItem;
  @Output()
  selectionChange = new EventEmitter<any>();

  currentDate = new Date()

  constructor() {
  }
}
