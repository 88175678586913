import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {KeycloakTokenService} from '@taures/angular-commons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  username = this.token.name;
  constructor(private token: KeycloakTokenService) {
  }
}
