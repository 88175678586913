import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {cloneDeepWith} from 'lodash-es';
import * as moment from 'moment';
import {SnackbarService} from '@taures/angular-commons';

export interface DocumentData {
  id?: string;
  customerId: number;
  created?: Date;
  signatureDate: Date;
  signaturePlace: string;

  firstName: string;
  lastName: string;
  birthday: Date;
}

export interface ProxyContractData extends DocumentData {
  street: string;
  postalCode: string;
  city: string;
}

export enum Consent { No = 'NO', Yes = 'YES', Restricted = 'RESTRICTED' }

export interface ContactPermission {
  consent: Consent;
  restrictTo?: string;
}

export class BrokerContractItem {
  existing: boolean;
  disabled: boolean;

  constructor(public id: number,
              public title: string,
              public selected?: boolean,
              public validFrom?: Date,
              public validTo?: Date,
              public note?: string) {
    if (selected) {
      this.existing = true;
    }
  }
}

export enum BrokerContractStatus { Current = 'CURRENT', Outdated = 'OUTDATED', None = 'NONE' }

export interface BrokerContractData extends ProxyContractData {
  fixedLine: ContactPermission;
  mobile: ContactPermission;
  email: ContactPermission;
  status: BrokerContractStatus;
  supplement: boolean;

  serviceContractDate: Date;
  creditBrokerageAgreementDate: Date;

  items: BrokerContractItem[];

  latestProxyContractVersion: boolean
}

export function isBrokerContractData(data: any): data is BrokerContractData {
  return data && data.items;
}

@Injectable()
export class DocumentGeneratorService {
  constructor(private http: HttpClient, private snackbarService: SnackbarService) {
  }

  downloadDocument(type: string, data: BrokerContractData | DocumentData): Observable<File> {
    if (isBrokerContractData(data)) {
      data.items = data.items.map(item => ({
        ...item,
        validFrom: item.selected ? (!item.validFrom ? new Date() : item.validFrom) : null
      } as any));
    }
    const jsonData = cloneDeepWith(data, value => {
      if (value instanceof Date || moment.isMoment(value)) {
        return moment(value).format('YYYY-MM-DD');
      }
    });
    delete jsonData.created
    return this.http.post(`document-generator/api/document/${type}`, jsonData, {
      observe: 'response',
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf'
      }
    })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const contentType = response.headers.get('Content-Type');
          let fileName = 'file';

          const match = response.headers.get('Content-Disposition').match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/);
          if (match && match.length > 1) {
            fileName = match[1];
          }
          return new File([response.body], decodeURI(fileName), {type: contentType});
        }),
        catchError(() => {
          this.snackbarService.queueToastMessage({
            title: 'Fehler',
            message: 'Dokument konnte nicht generiert werden.',
            duration: 5000,
            notificationType: 'error'
          });
          return of(null);
        }));
  }

  getBrokerContractData(customerId: string, reset: boolean = false): Observable<BrokerContractData> {
    return this.http.get<BrokerContractData>(`document-generator/api/broker-contract/${customerId}?reset=${reset}`);
  }

  getProxyContractData(customerId: string): Observable<ProxyContractData> {
    return this.http.get<ProxyContractData>(`document-generator/api/proxy-contract/${customerId}`);
  }
}
