import {Injectable} from '@angular/core';

import {from, Observable} from 'rxjs';
import {MapGeocoder} from "@angular/google-maps";
import {map, switchMap} from "rxjs/operators";
import {Loader} from "@googlemaps/js-api-loader"
import {environment} from '../../environments/environment';

@Injectable()
export class GeoLocationService {
  loader: Loader

  constructor(private geocoder: MapGeocoder) {
    this.loader = new Loader({
      apiKey: environment.googleMapsAPIKey,
      version: 'weekly',
    });
  }

  public getPosition(): Observable<GeolocationPosition> {
    return new Observable((observer) => {
      navigator.geolocation.getCurrentPosition((pos: GeolocationPosition) => {
        observer.next(pos);
      })
    });
  }

  getLocality(): Observable<string> {
    return this.getPosition().pipe(
      switchMap((position) =>
        from(this.loader.importLibrary('maps')).pipe(
          map(() => (position))
        )
      ),
      switchMap((position) =>
        this.geocoder.geocode({location: {lat: position.coords.latitude, lng: position.coords.longitude}})
      ),
      map((response) => {
        for (const result of response.results) {
          for (const addressComponent of result.address_components) {
            for (const type of addressComponent.types) {
              if (type === 'locality') {
                return addressComponent.long_name;
              }
            }
          }
        }
        return null;
      })
    )
  }
}
